import _ from 'lodash';
import './scss/_general.scss';
import smoothscroll from 'smoothscroll-polyfill';
import Glide from '@glidejs/glide';

// kick off the polyfill!
smoothscroll.polyfill();

// Sticky Header
function findOffset(element) {
  let top = 0,
    left = 0;

  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while (element);

  return {
    top: top,
    left: left,
  };
}

// Sticky
window.onload = function () {
  let stickyHeader = document.getElementById('sticky');
  let stickyPlaceholder = document.getElementById('stickyPlaceholder');
  let headerOffset = findOffset(stickyHeader);

  window.onscroll = function () {
    // body.scrollTop is deprecated and no longer available on Firefox
    let bodyScrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (bodyScrollTop > headerOffset.top) {
      stickyHeader.classList.add('fixed');
      stickyPlaceholder.classList.remove('hidden');
    } else {
      stickyHeader.classList.remove('fixed');
      stickyPlaceholder.classList.add('hidden');
    }
  };
};

// Hamburger
let hamburger = document.getElementById('navHamburger');
let navList = document.querySelectorAll('.navigation .navigation__link');
hamburger.addEventListener('click', () => {
  hamburger.classList.toggle('change');
  navList.forEach((el) => el.classList.toggle('hidden'));
});

// Glide settings
const glideSliders = document.querySelectorAll('.glide');
const sliderOptions = {
  type: 'carousel',
  autoplay: 3000,
  animationDuration: 1000,
  hoverpause: true,
}

glideSliders.forEach(slider=>{
  new Glide(slider, sliderOptions).mount();
})

/////
const options = { 
  type: "carousel",
  animationDuration: 600,
  gap: 0
};

const carousels = document.querySelectorAll(".carousel");

Object.values(carousels).map(carousel => {
  new Glide(carousel, options).mount();
});




